import podcast_icon from 'assets/Icons/telecom_accent.svg';

const { getCareerLinks } = require('../../utils/environment');
const {
  CAREER_LINK,
  VACANCY_LINK,
  TRAINEE_LINK,
  JAVA_TRAINEE_LINK,
  DEVOPS_TRAINEE_LINK,
  COURSES_LINK,
  coursesLinks,
} = getCareerLinks();

export const links = [
  {
    href: '/about-us',
    text: 'О нас',
  },
  {
    text: 'Услуги',
    links: [
      {
        href: '/services/it-consulting',
        text: 'Консалтинг',
      },
      {
        href: '/1C',
        text: '1С-разработка',
      },
      {
        href: '/services/financial-services',
        text: 'ФинТех',
      },
      {
        href: '/services/managed-delivery',
        text: 'Проектная разработка',
      },
      {
        href: '/services/software-team-staff-augmentation',
        text: 'Расширение команды',
      },
      {
        href: '/services/dedicated-team',
        text: 'Выделенная команда',
      },
    ],
  },
  {
    href: '/our-projects',
    text: 'Проекты',
  },
  {
    text: 'Работа в ASTON',
    links: [
      {
        href: CAREER_LINK,
        text: 'Карьера',
        isBlank: true,
      },
      {
        href: VACANCY_LINK,
        text: 'Вакансии',
        isBlank: true,
      },
      {
        text: 'Стажировка',
        links: [
          {
            href: TRAINEE_LINK,
            text: 'О стажировке',
          },
          {
            href: JAVA_TRAINEE_LINK,
            text: 'Java',
          },
          {
            href: DEVOPS_TRAINEE_LINK,
            text: 'DevOps',
          },
        ],
      },
      {
        text: 'Курсы',
        isBlank: true,
        links: [
          {
            href: COURSES_LINK,
            text: 'Все курсы',
          },
          {
            href: coursesLinks.COURSE_SYSTEMS_ANALYST,
            text: 'Системный аналитик',
          },
          {
            href: coursesLinks.COURSE_JAVA_DEVELOPER,
            text: 'Разработчик Java',
          },
          {
            href: coursesLinks.COURSE_DATA_ENGENEER,
            text: 'Дата-инженер',
          },
          {
            href: coursesLinks.COURSE_DATA_ANALYST,
            text: 'Дата-аналитик',
          },
          {
            href: coursesLinks.COURSE_1C_DEVELOPER,
            text: 'Разработчик 1C',
          },
          {
            href: coursesLinks.COURSE_1C_ANALYST,
            text: 'Аналитик 1C',
          },
          {
            href: coursesLinks.COURSE_QA_JAVA,
            text: 'QA Engineer Java',
          },
        ],
      },
    ],
  },
  {
    href: '/radio',
    icon: podcast_icon,
    text: 'ASTON Подкаст',
  },
];

export const variants = {
  open: {
    opacity: 1,
    visibility: 'visible',
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
      visibility: { delay: 0.02 },
    },
  },
  closed: {
    opacity: 0,
    visibility: 'hidden',
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      visibility: { delay: 0.25 },
    },
  },
};

exports.getBuildEnvironment = function () {
  return process.env.GATSBY_BUILD_ENV;
};

exports.getIsSsr = function () {
  return process.env.NODE_ENV === 'production';
};

exports.getApiUrl = function () {
  return process.env.GATSBY_API_URL || '';
};

exports.getAssetUrl = function () {
  return process.env.GATSBY_STATIC_URL || '';
};

exports.getCareerLinks = function () {
  const CAREER_LINK = process.env.GATSBY_CAREER_URL || '';
  const VACANCY_LINK = process.env.GATSBY_VACANCY_URL || '';
  const TRAINEE_LINK = process.env.GATSBY_TRAINEE_URL || '';
  const JAVA_TRAINEE_LINK = `${TRAINEE_LINK}/java`;
  const DEVOPS_TRAINEE_LINK = `${TRAINEE_LINK}/devops`;
  const COURSES_LINK = process.env.GATSBY_COURSES_URL || '';

  const coursesLinks = {
    COURSE_SYSTEMS_ANALYST: `${COURSES_LINK}/sa`,
    COURSE_JAVA_DEVELOPER: `${COURSES_LINK}/java`,
    COURSE_DATA_ENGENEER: `${COURSES_LINK}/de`,
    COURSE_DATA_ANALYST: `${COURSES_LINK}/data-analyst`,
    COURSE_1C_DEVELOPER: `${COURSES_LINK}/1c-developer`,
    COURSE_1C_ANALYST: `${COURSES_LINK}/1c`,
    COURSE_QA_JAVA: `${COURSES_LINK}/qa-java`,
  };

  return {
    CAREER_LINK,
    VACANCY_LINK,
    TRAINEE_LINK,
    JAVA_TRAINEE_LINK,
    DEVOPS_TRAINEE_LINK,
    COURSES_LINK,
    coursesLinks,
  };
};

import { Box, Collapse, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import NavItemMobile from 'shared/NavItemMobile';

export default function NestedMenuNavItemMobile({ text, isBlank, links = [] }) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <NavItemMobile
        text={text}
        toggle={onToggle}
        sxText={{ fontSize: '12px' }}
        isOpen={isOpen}
        linkProps={{
          background: isOpen ? 'bg.grayLight' : undefined,
          borderRadius: '60px',
          justifyContent: 'space-between',
        }}
      />
      <Box pl="12px">
        <Collapse in={isOpen}>
          {links.map(({ text, href }, idx) => (
            <NavItemMobile
              key={idx}
              href={href}
              text={text}
              isBlank={isBlank}
              sxText={{ fontSize: '12px' }}
            />
          ))}
        </Collapse>
      </Box>
    </>
  );
}

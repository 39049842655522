import React from 'react';
import {
  VStack,
  Button,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import NavItemMobile from 'shared/NavItemMobile';
import { goToForm } from 'shared/lib/goToForm';
import NestedMenuNavItemMobile from 'shared/NestedMenuNavItemMobile';
import { EMPTY_OBJECT } from 'constants/global';
import { links, variants } from 'constants/header';
import { navItem } from 'constants/header/variantNavItem';
import ArrowImage from 'shared/ArrowImage';

const MobileNavigation = props => {
  const { toggleMenuOpen } = useBoundStore(headerSelector);
  const handleButton = () => {
    goToForm();
    toggleMenuOpen();
  };

  return (
    <VStack
      as={motion.nav}
      variants={variants}
      userSelect="none"
      h={{ mobile: 'calc(100vh - 44px)', tablet: 'calc(100vh - 74px)' }}
      p="32px 8px 32px 6px"
      pos="fixed"
      right={0}
      top={{ mobile: '43.7px', tablet: '73.7px' }}
      gap="24px"
      justifyContent="space-between"
      overflowX="scroll"
      zIndex={1050}
      bgColor="bg.white"
      {...props}
    >
      <VStack gap="24px">
        {links?.map((el, i) =>
          el.links ? (
            <Accordion as={motion.div} variants={navItem} key={i} minW="170px" allowToggle>
              <AccordionItem border="none">
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      w="full"
                      flex={1}
                      justifyContent="space-between"
                      gap="12px"
                      p="12px 14px"
                      background={isExpanded ? 'bg.grayLight' : undefined}
                      borderRadius="191px"
                      minW="180px"
                      _hover={EMPTY_OBJECT}
                    >
                      <Text align="start" textStyle="button.secondary">
                        {el.text}
                      </Text>
                      <ArrowImage isOpen={isExpanded} />
                    </AccordionButton>
                    <AccordionPanel
                      display="flex"
                      flexDirection="column"
                      motionProps={{ unmountOnExit: true }}
                      p="0"
                    >
                      {el.links.map((link, index) => {
                        if (link.links?.length) {
                          return (
                            <NestedMenuNavItemMobile
                              key={index}
                              text={link.text}
                              isBlank={link.isBlank}
                              links={link.links}
                            />
                          );
                        }

                        return (
                          <NavItemMobile
                            key={index}
                            href={link.href}
                            text={link.text}
                            isBlank={link.isBlank}
                            toggle={toggleMenuOpen}
                            sxText={{ fontSize: '12px' }}
                          />
                        );
                      })}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          ) : (
            <NavItemMobile
              key={i}
              toggle={toggleMenuOpen}
              {...el}
              sxText={{ lineHeight: '44px' }}
              linkProps={{ pt: '0', pb: '0' }}
            />
          ),
        )}
      </VStack>

      <Button as={motion.button} variants={navItem} size="secondary" onClick={handleButton}>
        Оставить заявку
      </Button>
    </VStack>
  );
};

export default MobileNavigation;

import downAngle from 'assets/Icons/DownAngle.svg';
import React from 'react';
import { Image } from '@chakra-ui/react';

function ArrowImage({ isOpen = false, isVerical = true, size = '18px' }) {
  const openCondition = isVerical ? 'rotate(-180deg)' : 'rotate(90deg)';
  const closeCondition = isVerical ? 'rotate(0deg)' : 'rotate(-90deg)';

  return (
    <Image
      src={downAngle}
      transform={isOpen ? openCondition : closeCondition}
      transition="transform .2s"
      filter="none"
      w={size}
      h={size}
      alt="toggle menu"
    />
  );
}

export default ArrowImage;

import React, { useState } from 'react';
import { useDisclosure, Text, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';

import NavItem from 'shared/NavItem';
import NestedMenuNavItem from 'shared/NestedMenuNavItem';

import { EMPTY_OBJECT } from 'constants/global';
import ArrowImage from 'shared/ArrowImage';

export const MENU_ITEM_PADDING = '8px 20px';
export const HOVER = {
  bg: 'bg.grayLight',
  borderRadius: '20px',
};

export default function MenuNavItem({ text, links = [] }) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [openedNestedMenuId, setOpenedNestedMenuId] = useState(null);
  const closeNestedMenu = () => setOpenedNestedMenuId(null);
  const openNestedMenu = id => setOpenedNestedMenuId(id);

  return (
    <Menu
      placement="bottom-start"
      flip={false}
      preventOverflow={false}
      isOpen={isOpen}
      onClose={onClose}
      isLazy
      boundary="scrollParent"
      closeOnSelect={false}
    >
      <MenuButton
        gap="8px"
        onClick={onToggle}
        flexShrink="0"
        sx={{
          span: {
            display: 'flex',
            gap: '8px',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <Text textStyle="button.header">{text}</Text>
        <ArrowImage isOpen={isOpen} size="24px" />
      </MenuButton>
      <MenuList
        pos="absolute"
        top={{ laptop: '38px', tablet: '26px' }}
        _focusVisible={{ outline: 'none' }}
        minW={{ laptop: '247px', tablet: '200px' }}
        display="flex"
        flexDirection="column"
        p="16px 4px"
        bgColor="bg.white"
        color="text.black"
        borderRadius="16px"
        boxShadow="none"
      >
        {links.map((link, idx) => {
          if (link.links?.length) {
            const isOpen = idx === openedNestedMenuId;
            return (
              <NestedMenuNavItem
                key={idx}
                text={link.text}
                links={link.links}
                isOpen={isOpen}
                onClickBtn={isOpen ? closeNestedMenu : () => openNestedMenu(idx)}
                zIndex={links.length - idx}
              />
            );
          }

          return (
            <MenuItem key={idx} p={MENU_ITEM_PADDING} _focus={EMPTY_OBJECT} _hover={HOVER}>
              <NavItem
                {...link}
                onClick={onClose}
                sxText={{
                  fontSize: { desktop: '20px', laptop: '18px', tablet: '14px' },
                  textAlign: 'left',
                }}
                linkProps={{
                  minH: '24px',
                }}
              />
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

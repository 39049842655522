import { motion } from 'framer-motion';
import React, { memo, useCallback } from 'react';
import { Text, Link as ChakraLink, Box } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { navItem } from 'constants/header/variantNavItem';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { EMPTY_OBJECT } from 'constants/global';
import ArrowImage from 'shared/ArrowImage';

const NavItemMobile = props => {
  const {
    isOpen = null,
    href = null,
    text,
    toggle = null,
    isBlank = false,
    sxText = null,
    children,
    linkProps,
    ...etcProps
  } = props;
  const { getPosition } = useGlobalAudioPlayer();
  const isHttp = href?.startsWith('http');
  const linkHandler = useCallback(
    e => {
      toggle && toggle();
      if (isBlank && getPosition() !== 0) {
        e.preventDefault();
        window.open(href, '_blank');
      }
    },
    [toggle, isBlank, getPosition],
  );

  return (
    <Box as={motion.div} variants={navItem} w="full" {...etcProps}>
      <ChakraLink
        as={href ? (isHttp ? 'a' : GatsbyLink) : 'div'}
        {...(href && isHttp ? { href } : { to: href })}
        w="full"
        textAlign="center"
        display="flex"
        alignItems="center"
        onClick={linkHandler}
        _hover={EMPTY_OBJECT}
        p="15px 14px"
        {...linkProps}
      >
        <Text textStyle="button.secondary" textAlign="left" sx={sxText}>
          {text}
        </Text>
        {isOpen !== null && <ArrowImage isOpen={isOpen} />}
      </ChakraLink>
    </Box>
  );
};
export default memo(NavItemMobile);

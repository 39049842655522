import React from 'react';
import {
  Box,
  Button,
  Link,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { activeTrackSelector } from 'app/selectors';
import ThemedImage from 'shared/ThemedImage';
import { transformDate } from 'shared/lib/transformDate';

import { RADIO_TG_LINK } from 'constants/global';
import Playlist from 'assets/Icons/Playlist.svg';

export default function PlaylistControl({ last5RadioTracks, imgProps = null }) {
  const { activeTrackId, setActiveTrack } = useBoundStore(activeTrackSelector);

  return (
    <Popover gutter={5}>
      <PopoverTrigger>
        <Button variant="default" boxSize="24px" flexShrink={0} _hover={{ color: 'none' }}>
          <ThemedImage src={Playlist} boxSize="24px" {...imgProps} />
        </Button>
      </PopoverTrigger>
      <PopoverContent bgColor="bg.grayLight" borderRadius="16px" w="fit-content" p="24px">
        <VStack spacing="8px" mb="31px" w="121px">
          {last5RadioTracks?.map(({ publication_date, id }, i) => (
            <Box
              key={id}
              textStyle="radio"
              textAlign="center"
              w="full"
              borderRadius="40px"
              bgColor={activeTrackId === id && 'bg.accent'}
              border="1px solid"
              borderColor="bg.gray"
              p="5px"
              onClick={() => activeTrackId !== id && setActiveTrack(i, 'ASTON Радио шоу')}
              cursor="pointer"
              _hover={
                activeTrackId !== id && {
                  bgColor: 'bg.gray',
                }
              }
            >
              Выпуск {transformDate(publication_date)}
            </Box>
          ))}
        </VStack>
        <Link target="_blank" href={RADIO_TG_LINK}>
          <Button p="12px 0px" fontSize="14px" w="full">
            Все выпуски
          </Button>
        </Link>
      </PopoverContent>
    </Popover>
  );
}

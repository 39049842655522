import React, { memo } from 'react';
import { Button, HStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import { goToForm } from 'shared/lib/goToForm';
import MenuNavItem from 'shared/MenuNavItem';
import NavItem from 'shared/NavItem';

import { links } from 'constants/header';

const Navigation = props => {
  const { isLight } = useBoundStore(headerSelector);

  return (
    <HStack
      as="nav"
      gap={{ mobile: '10px', xxl: '20px' }}
      flexShrink="0"
      transition="color .2s linear"
      color={`text.${!isLight ? 'black' : 'white'}`}
      userSelect="none"
      whiteSpace="nowrap"
      {...props}
    >
      {links?.map((data, i) =>
        data.links?.length ? (
          <MenuNavItem key={i} {...data} />
        ) : (
          <NavItem key={i} {...data} sxText={{ textAlign: 'center' }} />
        ),
      )}
      <Button
        size="secondary"
        onClick={goToForm}
        variant={isLight && 'light'}
        p={{ xxl: '20px 28px', mobile: '12px 18px' }}
      >
        Оставить заявку
      </Button>
    </HStack>
  );
};

export default memo(Navigation);

import React from 'react';
import { Text, VStack, HStack, Box, Fade } from '@chakra-ui/react';
import NavItem from 'shared/NavItem';
import { HOVER, MENU_ITEM_PADDING } from 'shared/MenuNavItem';

import ArrowImage from 'shared/ArrowImage';

const MENU_PADDING = '4px';
const MENU_GAP = '8px';

export default function NestedMenuNavItem({ text, links = [], isOpen, onClickBtn, zIndex }) {
  return (
    <Box pos="relative">
      <HStack
        onClick={onClickBtn}
        w="full"
        align="center"
        p={MENU_ITEM_PADDING}
        cursor="pointer"
        _hover={HOVER}
      >
        <Text textStyle="button.header" textAlign="left" flexGrow="1">
          {text}
        </Text>
        <ArrowImage isOpen={isOpen} isVerical={false} size="24px" />
      </HStack>
      <Fade in={isOpen}>
        <Box
          pos="absolute"
          top="0"
          transform="translateX(100%)"
          zIndex={zIndex}
          sx={{
            right: `calc(-${MENU_PADDING} * 2 + -${MENU_GAP})`,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-16px',
              bottom: '-16px',
              left: `-${MENU_PADDING}`,
              right: `-${MENU_PADDING}`,
              borderRadius: '16px',
              background: 'white',
              border: '1px solid',
              borderColor: 'line.gray',
            },
          }}
        >
          <VStack pos="relative" alignItems="flex-start" gap="0">
            {links.map(({ text, href }, idx) => (
              <NavItem
                key={idx}
                text={text}
                href={href}
                w="full"
                p={MENU_ITEM_PADDING}
                linkProps={{ minH: '24px' }}
                _hover={HOVER}
              />
            ))}
          </VStack>
        </Box>
      </Fade>
    </Box>
  );
}

import React, { memo } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Text, Link as ChakraLink, Box, Image } from '@chakra-ui/react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { motion } from 'framer-motion';

import { EMPTY_OBJECT } from 'constants/global';

const NavItem = ({
  text,
  icon = null,
  href = null,
  toggle = null,
  isBlank = false,
  sxText = null,
  linkProps = null,
  ...props
}) => {
  const { getPosition } = useGlobalAudioPlayer();
  const isHttp = href.startsWith('http');
  const linkHandler = e => {
    toggle && toggle();
    if (isBlank && getPosition() !== 0) {
      e.preventDefault();
      window.open(href, '_blank');
    }
  };
  return (
    <Box as={motion.div} flexShrink="0" {...props}>
      <ChakraLink
        as={isHttp ? 'a' : GatsbyLink}
        {...(isHttp ? { href } : { to: href })}
        w="full"
        display="flex"
        gap={{ tablet: '6px', xl: '12px' }}
        alignItems="center"
        onClick={linkHandler}
        isExternal={isHttp}
        _hover={EMPTY_OBJECT}
        {...linkProps}
      >
        {icon && <Image src={icon} alt="Изображение для навигационной кнопки" />}
        <Text textStyle="button.header" whiteSpace="nowrap" sx={sxText}>
          {text}
        </Text>
      </ChakraLink>
    </Box>
  );
};
export default memo(NavItem);
